import * as React from "react"
import { useEffect } from "react";
import { navigate } from "gatsby";
import bots from "./bots";
import Cookies from "universal-cookie";

const QT = () => {
    useEffect(() => {
        const cookies = new Cookies();
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        
        if (cookies.get('qt1')) {
            if (Object.keys(bots).includes(cookies.get('qt1'))) {
                window.location.assign(bots[cookies.get('qt1')].url + params.get('q'))
            } else {
                navigate('/');
            }
        } else {
            navigate('/');
        }
    })

    return(
        <></>
    )
}

export default QT;