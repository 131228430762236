export default Object.freeze({
    'aiomoji': {
        name: 'Aiomoji',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
        //ATC: https://aiomoji.io/quicktask/add?quicktask=32628219576386|https://nrml.ca/
        //FSR: same as ATC, open more tabs
    },
    'balkobot': {
        name: 'Balkobot',
        url: 'http://localhost:6776/?url='
        //ATC: http://localhost:6776/?url=https://nrml.ca/cart/32628219510850:1
        //FSR: sames as ATC, open more tabs
        //RANDOM: http://localhost:6776/?url=https://nrml.ca/products/air-jordan-3-retro-ct8532-401
        //Link Change
    },
    'cybersole': {
        name: 'Cybersole',
        url: 'https://cybersole.io/dashboard/tasks?quicktask=',
        //ATC: https://cybersole.io/dashboard/tasks?quicktask=https://nrml.ca/cart/32628219510850:1
        //FSR: sames as ATC, open more tabs
        //RANDOM: https://cybersole.io/dashboard/tasks?quicktask=https://nrml.ca/products/air-jordan-3-retro-ct8532-401
        //Link Change: https://cybersole.io/dashboard/tasks?linkchange=URLGOESHERE
    },
    'dashe': {
        name: 'Dashe',
        url: 'https://api.dashe.io/v1/actions/quicktask?url='
        //ATC: https://api.dashe.io/v1/actions/quicktask?url=https://stay-rooted.com/cart/39261475602455:1
        //FSR: sames as ATC, open more tabs
        //RANDOM: https://api.dashe.io/v1/actions/quicktask?url=https://stay-rooted.com/products/adidas-yeezy-700-v3-kyanite
        //Link Change: https://api.dashe.io/v1/actions/quicktask?url=https://stay-rooted.com/products/adidas-yeezy-700-v3-kyanite
    },
    'kilo': {
        name: 'Kilo',
        url: 'http://127.0.0.1:4555/qt?link=',
        //ATC: http://127.0.0.1:4555/qt?link=https://stay-rooted.com/cart/39261475602455:1
        //RANDOM: http://127.0.0.1:4555/qt?link=https://stay-rooted.com/products/adidas-yeezy-700-v3-kyanite
        //Link Change:
    },
    'nebula': {
        name: 'Nebula',
        url: 'http://localhost:7392/quicktask?url=',
        //ATC: http://localhost:7392/quicktask?url=https://store.unionlosangeles.com/cart/39282830278733:1
    },
    'oculus': {
        name: 'Oculus',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
    },
    'prism': {
        name: 'Prism',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
    },
    'polaris': {
        name: 'Polaris',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
    },
    'destroyer': {
        name: 'Project Destroyer',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
    },
    'sole': {
        name: 'Sole AIO',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
    },
    'tks': {
        name: 'The Kick Station',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
    },
    'tohru': {
        name: 'Tohru',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
    },
    'whatbot': {
        name: 'What Bot',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
    },
    'wrath': {
        name: 'Wrath',
        url: 'https://aiomoji.io/quicktask/add?quicktask='
    }
})