import * as React from "react"
import { Formik, Form, useField } from "formik";
import Cookies from "universal-cookie";
import bots from "./bots";

function SelectInputField({ label, options, ...props }) {
    const [field, meta] = useField({ ...props }); 
    return (
        <React.Fragment>
            <select {...field} {...props}>
                <option key='default' value='default' disabled selected>Select</option>
                {Object.keys(options).map(k => (
                    <option key={k} value={k}>{options[k].name}</option>
                ))}
            </select>
        </React.Fragment>
    )
}

class QuickTaskForm1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaved: false,
            qt1: undefined
        };
    }

    renderSuccess() {
        if (this.state.isSaved) {
            if (this.state.qt1 == undefined || this.state.qt1 === 'default') {
                return (
                    <p><span className="error">✖</span> Error saving settings</p>
                )
            } else {
                return (
                    <p><span>✓</span> Settings saved</p>
                )
            }
        }
    }

    render() {
        return(
            <React.Fragment>
                <Formik
                    initialValues = {
                        {
                            qt1: undefined
                        }
                    }
                    onSubmit={(values) => {
                        const cookies = new Cookies();
                        console.log(values)
                        cookies.set('qt1', values.qt1);
                        this.setState({ qt1: values.qt1 });
                        this.setState({ isSaved: true });
                    }}
                >
                    <div className="qt-form">
                        <Form>
                                <div className="qt1">
                                    <h2>Select bot for quicktask links</h2>
                                    <SelectInputField label="select" options={bots} name={"qt1"}/>
                                </div>
                                <div className="button">
                                    <button className="primary lg-custom" type="submit">Save</button>
                                    {this.renderSuccess()}
                                </div>
                        </Form>
                    </div>
                </Formik>
            </React.Fragment>
        )
    }
}

export default QuickTaskForm1;