import * as React from "react";
import { Link } from "gatsby";

const Footer = () => {
    return(
        <div className="footer">
            <div className="footer-item">
                <a href="https://prjctsolaris.com">Main site</a>
            </div>
            <div className="footer-item"> 
                <a href="https://prjctsolaris.com/feedback/">Report a bug</a>
            </div>
        </div>
    )
}

export default Footer;