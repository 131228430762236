import * as React from "react";
import QuickTaskForm1 from "./quickTaskForm1";

const Menu = () => {
    return(
        <div className="menu">
            <div className="menu-item">
                <h1>QuickTask <b>Settings</b></h1>
            </div>
            <div className="menu-item variant2">
                <QuickTaskForm1 />
            </div>
        </div>
    )
}

export default Menu;