import * as React from "react";

const Meta = () => {
    return(
        <div>
            <title>Project Solaris | QuickTask Dashboard</title>
            <meta name="og:title" property="og:title" content="Project Solaris | QuickTask Dashboard"/>
            <meta name="twitter:title" property="twitter:title" content="Project Solaris | QuickTask Dashboard"/>
            <meta name="description" content="Quicktask dashboard for Project Solaris monitors."/>
            <link rel="canonical" href="https://psol.xyz/"/>
        </div>
    )
}

export default Meta;